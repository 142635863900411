import { loadScript } from '@paypal/paypal-js'
import { storeToRefs } from 'pinia'

import type {
  HostedFieldsHostedFieldsFieldData,
  OnApproveActions,
} from '@paypal/paypal-js'
import type {
  PaypalError,
  PaypalErrorDetail,
  PaypalInitData,
  PaypalResponse,
} from 'checkout/types/paypal'
import type { Order } from '@/types/order'
import { useAsyncData } from '#build/imports'

const defaultErrorMessage =
  'Your order has not been finalized. Go back to order and try the payment again.'
const isLoading = ref(true)
const errors = ref<Error[]>([])

class PaypalException extends Error {
  details: PaypalErrorDetail[]

  constructor(error: PaypalError) {
    super(error.message)
    Object.setPrototypeOf(this, PaypalException.prototype)
    this.name = error.name
    this.details = error.details
  }

  getDetailsMessage = () => this.details?.[0]?.description || ''
  getDetailsType = () => this.details?.[0]?.issue || ''
}

export default () => {
  const { $notify } = useNuxtApp()
  const cartStore = useCartStore()
  const { cart, isOrderProcessing, latestOrderId } = storeToRefs(cartStore)
  const cartId = ref(cartStore.cart?.id || '')

  const buttonStyles: {
    layout?: 'vertical' | 'horizontal'
    color?: 'gold' | 'blue' | 'silver' | 'white' | 'black'
    shape?: 'rect' | 'pill'
  } = {
    layout: 'vertical',
    color: 'blue',
    shape: 'pill',
  }

  const hostedFieldsStyles = {
    input: {
      'font-family': 'Roboto, sans-serif',
      'font-size': '16px',
      color: '#546878',
    },
    'input::placeholder': {
      color: '#90A0B7',
    },
  }

  const hostedFieldsConfig = {
    number: {
      selector: '#card-number',
      placeholder: '4111 1111 1111 1111',
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123',
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: 'MM/YY',
    },
  }

  const loadPaypal = async () => {
    try {
      if (!cartId.value) {
        return navigateTo('/checkout')
      }

      const paypalInitData = await fetchInitData()

      return await loadScript({
        clientId: paypalInitData?.clientId || '',
        dataClientToken: paypalInitData?.clientToken || '',
        locale: 'en_US',
        currency: 'USD',
        intent: 'capture',
        components: 'buttons,hosted-fields',
        commit: true,
      })
    } catch (error) {
      return null
    }
  }

  const fetchInitData = async (): Promise<PaypalInitData | undefined> => {
    try {
      const { data, error } = await useApi<PaypalInitData>('paypal', {
        method: 'post',
      })
      if (error.value && !data.value) {
        throw new Error("PayPal's initial configuration data fetching failed.")
      }

      return data.value || undefined
    } catch (error) {
      $notify({ text: (error as Error).message, severity: 'error' })
      errors.value.push(error as Error)
    }
  }

  const initPaypalOrder = async (saveCard = false): Promise<string> => {
    isOrderProcessing.value = true

    try {
      const { data } = await useApi<PaypalResponse>('paypal/order', {
        method: 'post',
        body: {
          cartId: cartId.value,
          saveCard,
        },
      })

      if (!data.value) {
        throw new Error('No data found of successfully created order.')
      }

      return data.value.id
    } catch (error: unknown) {
      throw createError({
        statusCode: 404,
        statusMessage: 'Sending order data to paypal failed.',
        fatal: true,
      })
    }
  }

  const handlePaypalError = (
    error: PaypalException,
    actions?: OnApproveActions,
  ): string => {
    if (error.getDetailsType() === 'INSTRUMENT_DECLINED' && actions?.restart) {
      setTimeout(() => {
        actions.restart()
      }, 7000)

      $notify({
        text: 'The requested action could not be completed, was semantically incorrect, or failed business validation. Choose different payment method.',
        severity: 'error',
        timeout: 7000,
      })
    }

    cartStore.errorMessage = error.getDetailsMessage() || defaultErrorMessage
    return error.getDetailsType()
  }

  const capturePaypalOrder = async (
    actions?: OnApproveActions,
  ): Promise<void> => {
    try {
      // TODO adjust after fix useApi method
      const runtimeConfig = useRuntimeConfig()
      const baseURL = runtimeConfig.public.apiUrl
      const headers: { 'Content-Type'?: string; Authorization?: string } = {}

      headers['Content-Type'] = 'application/json'

      const authStore = useAuthStore()
      const authHeader = authStore.isLoggedIn
        ? `Bearer ${authStore.token}`
        : null
      if (authHeader) {
        headers.Authorization = authHeader
      }

      const data = await useAsyncData<Order>('order', () =>
        $fetch('paypal/capture', {
          method: 'post',
          baseURL,
          body: {
            cartId: cartId.value,
          },
          headers,
        }),
      )

      // TODO temporary ignore errors; related to Nuxt and useFetch updating
      // @ts-ignore
      if (data.error.value?.data) {
        // @ts-ignore
        if ('name' in data.error.value.data) {
          // @ts-ignore
          throw new PaypalException(data.error.value.data)
        }

        throw new Error(data.error.value?.message)
      }

      if (!data.data.value) {
        throw new Error('No data found of captured paypal payment.')
      }

      latestOrderId.value = data.data.value?.id || ''
      await navigateTo(`/checkout/payment/success`)
      isOrderProcessing.value = false
      cart.value = undefined
    } catch (error: unknown) {
      if (error instanceof Error) {
        cartStore.errorMessage = error?.message || defaultErrorMessage
      }

      if (error instanceof PaypalException) {
        const errorType = handlePaypalError(error, actions)
        if (errorType === 'INSTRUMENT_DECLINED') {
          return
        }
      }

      latestOrderId.value = ''
      await navigateTo(`/checkout/payment/failure`)
      isOrderProcessing.value = false
      cart.value = undefined
    }
  }

  const validateHostedField = (
    field: HostedFieldsHostedFieldsFieldData,
  ): string => {
    if (field.isEmpty) {
      return 'This field is required'
    }

    if (!field.isValid) {
      return 'This field is invalid'
    }

    return ''
  }

  return {
    errors,
    isLoading,
    buttonStyles,
    hostedFieldsConfig,
    hostedFieldsStyles,
    initPaypalOrder,
    loadPaypal,
    capturePaypalOrder,
    validateHostedField,
  }
}
