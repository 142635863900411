<script setup lang="ts">
import IconCalendarCheck from '@/assets/images/calendar-check.svg'
import IconCheckCircleWavy from '@/assets/images/check-circle-wavy.svg'
import IconChatCircle from '@/assets/images/chat-circle.svg'
import IconTag from '@/assets/images/tag.svg'

const { product, prices } = useSummary()
const cartStore = useCartStore()
const {
  isTagDialogVisible,
  updatedSavedTags,
  tagSuggestions,
  areOrdersProcessing,
  openTagDialogWithSuggestions,
} = useTags()

defineEmits<{ (e: 'clickPayButton'): void }>()
</script>

<template>
  <aside class="my-4 md:mt-0">
    <div
      v-if="product"
      class="sticky top-1 flex w-full flex-shrink-0 flex-col rounded-md p-4 shadow-around"
    >
      <NuxtPicture
        v-if="product.imageUrl"
        class="w-full"
        :img-attrs="{
          class: 'h-full w-full rounded-md object-cover h-[193px]',
        }"
        :src="product.imageUrl"
        :alt="product.imageAlt"
        loading="lazy"
      />
      <div
        class="break-words border-b border-gray-500 pb-3 pt-6 text-base font-medium leading-5 text-gray-200"
      >
        {{ product.name }}
      </div>
      <div
        v-if="product.revisions"
        class="flex items-center border-b border-gray-500 py-5"
      >
        <IconChatCircle
          class="mr-2"
          aria-hidden="true"
        />
        <span>
          {{ product.revisions }}
        </span>
      </div>
      <div
        v-if="product.deliveryTime"
        class="flex items-center border-b border-gray-500 py-5"
      >
        <IconCalendarCheck
          class="mr-2"
          aria-hidden="true"
        />
        <span> {{ product.deliveryTime }} Delivery </span>
      </div>

      <div class="flex min-h-[65px] flex-wrap border-b border-gray-500 py-3">
        <span class="mr-[5px] mt-3 shrink-0">
          Post on Social{{ product.platforms.length ? '' : ': No' }}
        </span>
        <div
          v-if="product.platforms.length"
          class="my-px flex-1 items-center justify-center"
        >
          <Component
            :is="useIconComponent(getColorfulSocialIcon(item.toLowerCase()))"
            v-for="(item, index) in product.platforms"
            :key="index"
            :aria-label="item"
            class="mx-[5px] my-2 inline-flex"
            :height="24"
          />
        </div>
      </div>

      <div
        v-if="product.licensingRights && product.licensingRights !== 'None'"
        class="flex items-center border-b border-gray-500 py-5"
      >
        <IconCheckCircleWavy
          class="mr-2 w-[22px]"
          aria-hidden="true"
        />
        <span>{{ product.licensingRights }} licensing rights</span>
      </div>

      <ul class="flex flex-col gap-3 pt-5">
        <li class="flex items-center justify-between">
          <span>Collab Price</span>
          <span class="text-gray-200">
            {{ prices.subtotalPrice }}
          </span>
        </li>
        <li
          v-if="product.isExtraDeliveryAvailable"
          class="flex items-center justify-between"
        >
          <span>Extra Fast Delivery ({{ product.extraDeliveryTime }})</span>
          <span class="text-gray-200">
            {{ prices.extraDeliveryFee }}
          </span>
        </li>
        <li class="flex items-center justify-between">
          <span>Service Fee</span>
          <span class="text-gray-200">
            {{ prices.platformFee }}
          </span>
        </li>
      </ul>

      <h2 class="my-5 flex items-center justify-between">
        <span class="text-xl font-medium">Total</span>
        <span class="text-xl font-medium">
          {{ prices.totalPrice }}
        </span>
      </h2>
      <Button
        outlined
        class="mb-4 flex justify-center gap-2.5 py-3"
        @click="openTagDialogWithSuggestions"
      >
        <IconTag />
        Tag Collab
      </Button>
      <LazyAccountTagDialog
        v-if="isTagDialogVisible"
        v-model:visible="isTagDialogVisible"
        :available-tags="tagSuggestions"
        :saved-tags="updatedSavedTags"
        :loading="cartStore.isOrderProcessing"
        :cart-id="cartStore.cart?.id"
        :is-loader-visible="areOrdersProcessing"
        @update-saved-tags="(tags) => (updatedSavedTags = tags)"
        @close-dialog="isTagDialogVisible = false"
      />
      <Button
        :disabled="cartStore.isOrderProcessing"
        class="p-button-rounded justify-center"
        :label="cartStore.isOrderProcessing ? 'Processing' : 'Confirm & Pay'"
        @click="$emit('clickPayButton')"
      />
      <div
        class="mt-4 flex items-center justify-center text-sm font-medium leading-4 text-green-200"
      >
        <i class="pi pi-lock mr-2 text-xl" />
        SSL Secured Payment
      </div>
    </div>
  </aside>
</template>
