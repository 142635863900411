<script setup lang="ts">
import type { CartResponse } from 'checkout/types/cart-response'

useHead({
  title: 'Checkout',
  meta: [
    {
      name: 'description',
      content: 'Checkout for your content creation order on Branded Content',
    },
    { name: 'robots', content: 'noindex, nofollow' },
  ],
})

const cartId = useCookie('cartId')
const cartStore = useCartStore()

if (!cartStore.cart && cartId.value) {
  await cartStore.fetch(cartId.value)
}

export interface PaypalCreditCardForm {
  placeOrder: Function
}
const paymentMethodPaypalRef = ref<PaypalCreditCardForm | null>(null)

const placeOrder = () => {
  paymentMethodPaypalRef.value?.placeOrder()
}

const { product, prices } = useSummary()
const logCheckoutAbandon = (cart?: CartResponse, redirectUrl = '') => {
  if (!cart || !product.value || !prices.value) {
    return
  }

  useKlaviyo('checkout_abandon', {
    id: cart.id,
    customerId: cart.customerId,
    product: product.value,
    prices: prices.value,
    redirectUrl,
  })
}

onBeforeRouteLeave(({ fullPath }) => {
  if (fullPath !== '/checkout/payment/success') {
    logCheckoutAbandon(cartStore.cart, fullPath)
  }
})

onMounted(() => {
  window.addEventListener('beforeunload', () => {
    logCheckoutAbandon(cartStore.cart)
  })
})
</script>

<template>
  <div class="my-8">
    <NuxtLink
      to="/"
      class="p-button p-button-text p-button-small"
    >
      <i class="pi pi-arrow-left p-button-icon p-button-icon-left mr-4" />
      <span class="p-button-label">Back to Collab</span>
    </NuxtLink>

    <div
      v-if="cartStore.cart"
      class="md:grid md:grid-cols-[1fr_360px] md:gap-x-8 md:gap-y-0"
    >
      <section>
        <h1 class="mb-8 mt-4">Payment Options</h1>
        <CheckoutPaymentMethodPaypal ref="paymentMethodPaypalRef" />
      </section>

      <CheckoutSummary @click-pay-button="placeOrder" />
    </div>

    <template v-else>
      <h1 class="text-center">Shopping cart</h1>
      <div class="my-12 text-center">
        <p>You have no items in your shopping cart.</p>
        <NuxtLink
          to="/"
          class="p-button mt-4"
        >
          Continue shopping
        </NuxtLink>
      </div>
    </template>
  </div>
</template>
