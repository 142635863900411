<script setup lang="ts">
import type { PayPalNamespace } from '@paypal/paypal-js'

const {
  isLoading,
  errors,
  loadPaypal,
  initPaypalOrder,
  capturePaypalOrder,
  buttonStyles,
} = usePaypal()

interface PaypalCreditCardForm {
  placeCreditCardOrder: Function
}

const paypal = ref<PayPalNamespace | null>(null)
const paypalCreditCardFormRef = ref<PaypalCreditCardForm | null>(null)

const cartStore = useCartStore()
const { $notify } = useNuxtApp()

const placeOrder = () => {
  paypalCreditCardFormRef.value?.placeCreditCardOrder()
}

defineExpose({
  placeOrder,
})

paypal.value = (await loadPaypal()) as PayPalNamespace | null
cartStore.errorMessage = ''

onMounted(() => {
  if (paypal.value && paypal.value.Buttons) {
    paypal.value
      .Buttons({
        style: buttonStyles,
        createOrder: async () => {
          return await initPaypalOrder(false)
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onApprove: (data, actions) => {
          cartStore.isOrderProcessing = false
          return capturePaypalOrder(actions)
        },
        onCancel: async () => {
          $notify('You canceled the payment. You can still pay for your order.')
          if (cartStore.cart) {
            await cartStore.fetch(cartStore.cart.id) // update cart version after capture PP order
          }
          cartStore.isOrderProcessing = false
        },
      })
      .render('#paypal-container')
    isLoading.value = false
  }
})
</script>

<template>
  <div>
    <div
      v-if="isLoading"
      class="flex h-[400px] w-full items-center justify-center"
    >
      <div class="w-8">
        <BaseLoader />
      </div>
    </div>

    <div
      id="paypal-container"
      class="mt-4"
    />

    <ClientOnly>
      <Divider
        v-if="paypal?.Buttons && paypal?.HostedFields?.isEligible()"
        align="center"
        layout="horizontal"
        class="h-4"
      >
        <span>or pay by Credit Card</span>
      </Divider>

      <LazyCheckoutPaymentMethodPaypalCreditCardForm
        v-if="paypal?.HostedFields?.isEligible()"
        ref="paypalCreditCardFormRef"
        :component="paypal.HostedFields"
      />
      <LazyCheckoutPaymentMethodPaypalError
        v-if="errors.length"
        :errors="errors"
      />
    </ClientOnly>
  </div>
</template>
